import React from "react"
import EmailLink from "../../components/EmailLink"
import * as styles from "./styles.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter } from "@fortawesome/free-brands-svg-icons"

const Footer = () => {
  return (
    <div className={styles.container}>
      <div className={styles.grid}>
        <div>
          <a
            href="https://twitter.com/etfstockreport"
            target="_blank"
            rel="noreferrer"
            className="text"
          >
            <FontAwesomeIcon icon={faTwitter} size="lg" /> / @etfstockreport
          </a>
        </div>
        <p>
          177 N. US Hwy 1 Unit #288
          <br />
          Tequesta, FL 33469
          <br />
          <EmailLink email="celestialcreativesolutions@gmail.com" />
        </p>
      </div>

      <div className={styles.copy}>
        <p>
          <span>©</span>
          <span>
            2021, Celestial Creative Solutions, LLC. All Rights Reserved.
          </span>
        </p>
      </div>
    </div>
  )
}

export default Footer
