import React, { useEffect } from "react"
import { navigate } from "gatsby"
import { setValToLS } from "../utils/helpers"

const Signout = () => {
  useEffect(() => {
    setValToLS("etfsrId", null)
    setValToLS("etfsrToken", null, true)
    navigate("/")
  }, [])

  return () => <div />
}

export default Signout
