import React, { useState, useEffect, Fragment } from "react"
import { Link } from "gatsby"
import { API_URL, getValFromLS } from "../../utils/helpers"
//import Pdf from "./Pdf"
import Download from "./Download"
import Alert from "../../components/Alert"
import * as styles from "./styles.module.css"

const Reports = () => {
  const [details, setDetails] = useState({
    hdg: "Posted reports for the week",
    items: [],
  })
  const [selected, setSelected] = useState({})
  const [alert, setAlert] = useState({
    type: "working",
    text: "",
  })

  const token = getValFromLS("etfsrToken", true)

  useEffect(() => {
    console.log("useEffect called")

    const init = async () => {
      const url = new URL(`${API_URL}/auth/reportsInit`)
      const params = {
        token,
      }
      url.search = new URLSearchParams(params)

      try {
        const response = await fetch(url, {
          method: "GET",
          cache: "no-store",
        })
        const json = await response.json()
        if (json && json.resp === 1) {
          let selected = {}
          json.items.forEach(item => {
            if (item.selected) {
              selected = item
            }
          })
          setSelected(selected)
          setDetails({ ...json })
          setAlert({})
        } else {
          setAlert({
            type: "error",
            text: json.text,
          })
        }
      } catch (error) {
        setAlert({
          type: "error",
          text: "An error has occurred.",
        })
      }
    }

    init()
  }, [])

  return (
    <section className={styles.reports}>
      {details.items.length === 0 ? (
        <Alert data={alert} />
      ) : (
        <Fragment>
          <p className={styles.hdg}>{details.hdg}</p>
          <div className={styles.content}>
            <div className={styles.grid}>
              {details.items.map(item => {
                return item.pdf ? (
                  <button
                    type="button"
                    className={
                      selected.id === item.id ? `btn selected` : `btn `
                    }
                    key={item.id}
                    onClick={() => setSelected(item)}
                  >
                    {item.name}
                  </button>
                ) : (
                  <div className="btn na" key={item.id}>
                    {item.name}
                  </div>
                )
              })}
            </div>

            {selected.id && selected.notes && (
              <p className={styles.notes}>{selected.notes}</p>
            )}

            {selected.id && (
              <div className={styles.grid2}>
                {/*
                <Pdf
                  url={`${API_URL}/auth/viewpdf?token=${token}&date=${selected.date}`}
                />*/}
                <Download item={selected} token={token} />
              </div>
            )}

            {!selected.id && (
              <div className={styles.btnLinkContainer}>
                <Link
                  to="/sub/archive"
                  className="btn"
                >{`Report Archive`}</Link>
              </div>
            )}
          </div>
        </Fragment>
      )}
    </section>
  )
}
export default Reports
