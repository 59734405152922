import React, { useState, useEffect, useRef, Fragment } from "react"
import { API_URL, getValFromLS } from "../../utils/helpers"
import Alert from "../../components/Alert"
import Input from "../../components/Input"
import Select from "../../components/Select"
import Msg from "../../components/Msg"
import Submit from "../../components/Submit"
import * as styles from "./styles.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronCircleRight,
  faFilePdf,
} from "@fortawesome/pro-light-svg-icons"

const Archive = () => {
  const [data, setData] = useState({
    months: [],
    days: [],
  })
  const [report, setReport] = useState({})
  const [names, setNames] = useState({
    year: "2021",
  })
  const [errors, setErrors] = useState([])
  const [msg, setMsg] = useState({})
  const [alert, setAlert] = useState({
    type: "working",
    text: "",
  })

  const formElement = useRef(null)

  const token = getValFromLS("etfsrToken", true)

  useEffect(() => {
    console.log("useEffect called")

    const init = async () => {
      const url = new URL(`${API_URL}/auth/archiveInit`)
      const params = {
        token,
      }
      url.search = new URLSearchParams(params)

      try {
        const response = await fetch(url, {
          method: "GET",
          cache: "no-store",
        })
        const json = await response.json()
        if (json && json.resp === 1) {
          setData({ ...json })
          setAlert({})
        }
      } catch (error) {
        setAlert({
          type: "error",
          text: "An error has occurred.",
        })
      }
    }

    init()
  }, [])

  const handleUpdate = (name, value) => {
    setNames(names => ({ ...names, [name]: value ? value : "" }))
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setErrors([])
    setMsg({
      type: "working",
      text: "",
    })
    setReport({})

    const url = new URL(`${API_URL}/auth/archive`)
    let formData = new FormData()
    Object.entries(names).forEach(([key, value]) => {
      formData.append(key, value)
    })
    formData.append("token", token)

    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      })
      const json = await response.json()
      handleResponse(json, null)
    } catch (error) {
      handleResponse(null, error)
    }
  }

  const handleResponse = (json, error) => {
    if (json) {
      if (json.resp === 1) {
        setMsg({
          type: "success",
          text: json.text,
        })
        setReport({
          date: json.date,
          notes: json.notes,
          size: json.size,
        })
      } else {
        setErrors(json.fields)
        setMsg({
          type: "error",
          text: json.text,
        })
      }
    } else {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      })
    }
  }

  return (
    <section className={styles.archive}>
      {alert.type ? (
        <Alert data={alert} />
      ) : (
        <Fragment>
          <p className={styles.hdg}>Archive of posted reports</p>
          <div className={styles.content}>
            <form
              method="post"
              action="/"
              onSubmit={e => handleSubmit(e)}
              ref={formElement}
            >
              <p>Use the form below to locate a report from the archive.</p>

              <div className="three">
                <Select
                  name="month"
                  label="Select Month"
                  reqd={true}
                  autocomplete="off"
                  options={data.months.map(item => {
                    return {
                      value: item.value,
                      name: item.name,
                    }
                  })}
                  update={handleUpdate}
                  errors={errors}
                />

                <Select
                  name="day"
                  label="Select Day"
                  reqd={true}
                  autocomplete="off"
                  options={data.days.map(item => {
                    return {
                      value: item.value,
                      name: item.name,
                    }
                  })}
                  update={handleUpdate}
                  errors={errors}
                />

                <Input
                  name="year"
                  label="Year"
                  reqd={true}
                  autocomplete="off"
                  update={handleUpdate}
                  errors={errors}
                  value="2021"
                />
              </div>

              <div className="msgSubmit">
                {msg.type && <Msg data={msg} />}

                <div className={msg.type === "working" ? "hidden" : ""}>
                  <Submit name="Submit Date" icon={faChevronCircleRight} />
                </div>
              </div>
            </form>

            {report.date && (
              <div className={styles.downloadContainer}>
                <div>
                  <FontAwesomeIcon icon={faFilePdf} size="4x" />
                  <p>
                    {report.date}
                    <br />
                    <small>{report.size}</small>
                  </p>
                </div>

                <div>
                  <a
                    href={`${API_URL}/auth/pdf?token=${token}&date=${report.date}`}
                    className="btn"
                  >{`Download Report`}</a>
                </div>
              </div>
            )}
          </div>
        </Fragment>
      )}
    </section>
  )
}
export default Archive
