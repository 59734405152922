import React, { useState, useEffect, Fragment } from "react"
import { Router } from "@reach/router"
import { API_URL, getValFromLS, setValToLS } from "../utils/helpers"
import Helmet from "../components/Helmet"
import PrivateRoute from "../components/PrivateRoute"
import Header from "../subPage/Header" /* header for sub area */
import Footer from "../subPage/Footer" /* footer for sub area */
import One from "../subPage/One"
import Reports from "../subPage/Reports"
import Archive from "../subPage/Archive"
import Account from "../subPage/Account"
import Signout from "../subPage/Signout"

const SubPage = () => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    console.log("useEffect called")

    const init = async id => {
      const url = new URL(`${API_URL}/autosignin`)
      const params = {
        id,
      }
      url.search = new URLSearchParams(params)

      try {
        const response = await fetch(url, {
          method: "GET",
          cache: "no-store",
        })
        const json = await response.json()
        if (json && json.resp === 1) {
          setValToLS("etfsrToken", json.token, true)
        }
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }

    if (!getValFromLS("etfsrToken", true) && getValFromLS("etfsrId")) {
      init(getValFromLS("etfsrId"))
    } else {
      setLoading(false)
    }
  }, [])

  return (
    <Fragment>
      {!loading ? (
        <Fragment>
          <Helmet title="Subscribers Area" descr="" admin={true} />
          <Header />
          <One />
          <Router>
            <PrivateRoute exact path="/sub" component={Reports} />
            <PrivateRoute exact path="/sub/archive" component={Archive} />
            <PrivateRoute exact path="/sub/account" component={Account} />
            <PrivateRoute exact path="/sub/signout" component={Signout} />
          </Router>
          <Footer />
        </Fragment>
      ) : null}
    </Fragment>
  )
}

export default SubPage
