import React from "react"
import { Link } from "gatsby"
import { API_URL } from "../../utils/helpers"
import * as styles from "./styles.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilePdf } from "@fortawesome/pro-light-svg-icons"

const Download = ({ item, token }) => {
  return (
    <div className={styles.downloadContainer}>
      <div>
        <FontAwesomeIcon icon={faFilePdf} size="4x" />
        <p>
          {item.date}
          <br />
          <small>{item.size}</small>
        </p>
      </div>

      <div>
        <a
          href={`${API_URL}/auth/pdf?token=${token}&date=${item.date}`}
          className="btn"
        >{`Download Report`}</a>
        <Link to="/sub/archive" className="btn">{`Report Archive`}</Link>
      </div>
    </div>
  )
}

export default Download
