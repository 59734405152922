import React from "react"
import H1 from "../../components/H1"
import * as styles from "./styles.module.css"

const One = () => {
  return (
    <section className={styles.one}>
      <H1 text="Subscriber’s Area"></H1>

      {/*}<div className={styles.grid}>
        <div>
          <span>This week’s Posted Reports.</span>
        </div>
      </div>*/}
    </section>
  )
}

export default One
