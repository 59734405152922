import React from "react"

const EmailLink = ({ email }) => {
  return email === "celestialcreativesolutions@gmail.com" ? (
    <a
      className="text"
      href="mailto:&#99;&#101;&#108;&#101;&#115;&#116;&#105;&#97;&#108;&#99;&#114;&#101;&#97;&#116;&#105;&#118;&#101;&#115;&#111;&#108;&#117;&#116;&#105;&#111;&#110;&#115;&#64;&#103;&#109;&#97;&#105;&#108;&#46;&#99;&#111;&#109;"
    >
      &#99;&#101;&#108;&#101;&#115;&#116;&#105;&#97;&#108;&#99;&#114;&#101;&#97;&#116;&#105;&#118;&#101;&#115;&#111;&#108;&#117;&#116;&#105;&#111;&#110;&#115;&#64;&#103;&#109;&#97;&#105;&#108;&#46;&#99;&#111;&#109;
    </a>
  ) : null
}

export default EmailLink
