import React, { Fragment } from "react"
import { Link } from "gatsby"
import { getValFromLS } from "../../utils/helpers"
import logo from "../../img/logo-tm.svg"
import * as styles from "./styles.module.css"

const Header = () => {
  const items = [
    {
      id: "/sub",
      name: "Reports",
      btn: true,
    },
    {
      id: "/sub/account",
      name: "Account",
      btn: true,
    },
    {
      id: "/sub/signout",
      name: "Sign Out",
      btn: true,
    },
  ]

  return (
    <Fragment>
      <div className={styles.containerDesktop}>
        <div className={styles.left}>
          <Link to="/" className={styles.logo}>
            <img src={logo} alt="ETF Stock Report Logo" />
          </Link>
        </div>

        <div className={styles.right}>
          {items.map(item => {
            return getValFromLS("etfsrToken", true) && item.btn ? (
              <Link
                to={`${item.id}`}
                className="btn"
                key={item.id}
                activeStyle={{
                  borderColor: "var(--green)",
                  color: "var(--green)",
                }}
              >
                {item.name}
              </Link>
            ) : null
          })}
        </div>
      </div>
    </Fragment>
  )
}

export default Header
