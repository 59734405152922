import React, { useState, useEffect, useRef, Fragment } from "react"
import { loadStripe } from "@stripe/stripe-js"
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js"
import { API_URL, STRIPE_KEY, getValFromLS } from "../../utils/helpers"
import Alert from "../../components/Alert"
import Input from "../../components/Input"
import InputStripe from "../../components/InputStripe"
import Checkbox from "../../components/Checkbox"
import Msg from "../../components/Msg"
import Submit from "../../components/Submit"
import * as styles from "./styles.module.css"
import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons"

const stripePromise = loadStripe(STRIPE_KEY)

const One = () => {
  const [details, setDetails] = useState({})
  const [names, setNames] = useState({})
  const [errors, setErrors] = useState([])
  const [msg, setMsg] = useState({})
  const [alert, setAlert] = useState({
    type: "working",
    text: "",
  })

  const formElement = useRef(null)
  const stripe = useStripe()
  const elements = useElements()

  const token = getValFromLS("etfsrToken", true)

  useEffect(() => {
    console.log("useEffect called")

    const init = async () => {
      const url = new URL(`${API_URL}/auth/accountInit`)
      const params = {
        token,
      }
      url.search = new URLSearchParams(params)

      try {
        const response = await fetch(url, {
          method: "GET",
          cache: "no-store",
        })
        const json = await response.json()
        if (json && json.resp === 1) {
          setDetails({
            fname: json.fname,
            lname: json.lname,
          })
          setNames({ ...json })
          setAlert({})
        } else {
          setAlert({
            type: "error",
            text: json.text,
          })
        }
      } catch (error) {
        setAlert({
          type: "error",
          text: "An error has occurred.",
        })
      }
    }

    init()
  }, [])

  const handleUpdate = (name, value) => {
    if (name === "newEmail" || name === "newCard") {
      setNames(names => ({ ...names, [name]: !value }))
    } else {
      setNames(names => ({ ...names, [name]: value ? value : "" }))
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setErrors([])
    setMsg({
      type: "working",
      text: "",
    })

    const url = new URL(`${API_URL}/auth/account`)
    let formData = new FormData()
    Object.entries(names).forEach(([key, value]) => {
      if (key === "newEmail" || key === "newCard") {
        formData.append(key, value ? "1" : "0")
      } else if (key === "stripe") {
        formData.append(key, JSON.stringify(value))
      } else {
        formData.append(key, value)
      }
    })
    formData.append("token", token)

    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      })
      const json = await response.json()
      handleResponse(json, null)
    } catch (error) {
      handleResponse(null, error)
    }
  }

  const handleResponse = (json, error) => {
    if (json) {
      if (json.resp === 1) {
        if (names.newCard) {
          handlePayment(names.stripe.cusId)
        } else {
          setNames(names => ({
            ...names,
            email: json.email ? json.email : names.email,
            newEmail: false,
          }))
          setDetails(details => ({
            ...details,
            fname: names.fname,
            lname: names.lname,
          }))
          setMsg({
            type: "success",
            text: json.text,
          })
        }
      } else {
        setErrors(json.fields)
        setMsg({
          type: "error",
          text: json.text,
        })
      }
    } else {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      })
    }
  }

  const handlePayment = async stripeCustomerId => {
    try {
      const result = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
        billing_details: {
          name: `${names.fname} ${names.lname}`,
          email: names.newEmail ? names.confirm1 : names.email,
        },
      })

      if (result.error) {
        setMsg({
          type: "error",
          text: result.error.message,
        })
      } else {
        handleSuccess(result.paymentMethod.id)
      }
    } catch (error) {
      setMsg({
        type: "error",
        text: "Please enter a new payment method.",
      })
      setErrors(["card"])
    }
  }

  const handleSuccess = async payMethId => {
    const url = new URL(`${API_URL}/auth/accountpayment`)
    let data = new FormData()
    Object.entries(names).forEach(([key, value]) => data.append(key, value))
    data.append("payMethId", payMethId)

    try {
      const response = await fetch(url, {
        method: "POST",
        body: data,
      })
      const json = await response.json()
      if (json && json.resp === 1) {
        setNames(names => ({
          ...names,
          email: json.email ? json.email : names.email,
          newEmail: false,
          newCard: false,
        }))
        setDetails(details => ({
          ...details,
          fname: names.fname,
          lname: names.lname,
        }))
        setMsg({
          type: "success",
          text: json.text,
        })
      } else {
        setErrors(json.fields)
        setMsg({
          type: "error",
          text: json.text,
        })
      }
    } catch (error) {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      })
    }
  }

  return (
    <section className={styles.account}>
      {alert.type ? (
        <Alert data={alert} />
      ) : (
        <Fragment>
          <p className={styles.hdg}>View / update your account details</p>
          <div className={styles.content}>
            <div className="one">
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th className="left">Name</th>
                    <th>Method</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="center">{`${names.id}`}</td>
                    <td className="left">
                      {`${details.fname} ${details.lname} (${names.email}`})
                    </td>
                    <td className="center">{`${names.stripe.card}`}</td>
                    {names.active ? (
                      <td className="center success">Active</td>
                    ) : (
                      <td className="center error">Inactive</td>
                    )}
                  </tr>
                </tbody>
              </table>
            </div>

            <form
              method="post"
              action="/"
              onSubmit={e => handleSubmit(e)}
              ref={formElement}
            >
              <p>Use the form below to update your account.</p>

              <div className="two">
                <Input
                  name="fname"
                  label="First Name"
                  reqd={true}
                  autocomplete="given-name"
                  update={handleUpdate}
                  errors={errors}
                  value={names.fname}
                />

                <Input
                  name="lname"
                  label="Last Name"
                  reqd={true}
                  autocomplete="family-name"
                  update={handleUpdate}
                  errors={errors}
                  value={names.lname}
                />
              </div>

              <div className="two">
                <Input
                  name="phone"
                  label="Phone"
                  reqd={true}
                  autocomplete="tel"
                  update={handleUpdate}
                  errors={errors}
                  value={names.phone}
                />

                <div className="empty" />
              </div>

              <div className="one">
                <Checkbox
                  name="newEmail"
                  label="Check to change your email address"
                  reqd={false}
                  click={handleUpdate}
                  checked={names.newEmail || false}
                />
              </div>

              <div
                className={names.newEmail ? "two" : "two hidden"}
                style={{ marginBottom: "25px" }}
              >
                <Input
                  name="confirm1"
                  label="Email Address"
                  reqd={true}
                  autocomplete="email"
                  update={handleUpdate}
                  errors={errors}
                />

                <Input
                  name="confirm2"
                  label="Confirm Email Address"
                  reqd={true}
                  autocomplete="email"
                  update={handleUpdate}
                  errors={errors}
                />
              </div>

              <div
                className={names.stripe.cusId === "comp" ? "one hidden" : "one"}
                style={{ marginTop: "5px" }}
              >
                <Checkbox
                  name="newCard"
                  label="Check to update your payment method"
                  reqd={false}
                  click={handleUpdate}
                  checked={names.newCard || false}
                />
              </div>

              <div
                className={names.newCard ? "one" : "one hidden"}
                style={{ marginBottom: "35px" }}
              >
                <div className="one">
                  <Elements stripe={stripePromise}>
                    <InputStripe
                      name="card"
                      label="Credit or Debit Card"
                      reqd={true}
                      errors={errors}
                    />
                  </Elements>
                </div>
              </div>

              <div className="msgSubmit">
                {msg.type && <Msg data={msg} />}

                <div className={msg.type === "working" ? "hidden" : ""}>
                  <Submit
                    name="Submit Account Updates"
                    icon={faChevronCircleRight}
                  />
                </div>
              </div>
            </form>
          </div>
        </Fragment>
      )}
    </section>
  )
}

const Account = () => {
  return (
    <Elements stripe={stripePromise}>
      <One />
    </Elements>
  )
}

export default Account
