import React from "react"
import { navigate } from "gatsby"
import { getValFromLS } from "../../utils/helpers"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  if (
    !getValFromLS("etfsrToken", true) &&
    typeof window !== `undefined` &&
    location.pathname !== `/signin`
  ) {
    navigate("/signin")
    return null
  }
  return <Component {...rest} />
}
export default PrivateRoute
